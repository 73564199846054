import React, { createContext, useState, useContext, useEffect } from 'react';

import axiosRequest from 'utils/axiosRequest';
import { useNavigate } from 'react-router-dom';
import ErrorModal from 'components/globalerror';
import config from '../../config';
import { isEqual } from 'lodash';
import { useLocalStorage } from 'hooks/useLocalStorage';
const ContactsContext = createContext();

export const useContacts = () => useContext(ContactsContext);

const ContactsProvider = ({ children }) => {
  const [errorResponse, setErrorResponse] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const hideErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [user] = useLocalStorage('user');
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const getContacts = async (page, append = true) => {
    // let data = {filters: arg.payload};
    // setIsLoading(true);
    // try {
    //   const response = await axiosRequest(
    //     'POST',
    //     `${config.apiUrl}contacts?limit=10&skip=${page + 1 * 2}`,
    //     null,
    //     {
    //       filters: {}
    //     },
    //     navigate
    //   );
    //   if (response.success === true) {
    //     const newContacts = response.data;
    //     if (newContacts.length < 10) {
    //       setHasMore(false);
    //     }
    //     if (append) {
    //       setContacts((prevContacts) => [...prevContacts, ...newContacts]);
    //     } else {
    //       setContacts(newContacts);
    //     }
    //     setIsLoading(false);
    //     return response?.data;
    //   } else {
    //     setErrorResponse(response);
    //     setErrorModalOpen(true);
    //   }
    // } catch (error) {
    //   console.error('Error fetching users:', error);
    // }
  };

  const fetchingContacts = async (page, append = true) => {
    setIsLoading(true);
    try {
      const response = await axiosRequest('POST', `${config.apiUrl}contacts?limit=500&skip=${page}`);
      const newContacts = response?.data;

      if (newContacts?.length < 2) {
        setHasMore(false);
      }

      if (append) {
        const combinedContacts = [...contacts, ...newContacts];
        if (!isEqual(contacts, combinedContacts)) {
          setContacts(combinedContacts);
        }
      } else {
        if (!isEqual(contacts, newContacts)) {
          setContacts(newContacts);
        }
      }
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    }
    setIsLoading(false);
  };

  const prefetchNextPage = async () => {
    console.log('prefetch');
    if (!hasMore || isFetchingNextPage) return;
    setIsFetchingNextPage(true);
    const nextPage = page + 1;
    try {
      const response = await axiosRequest(
        'POST',
        `${config.apiUrl}contacts?limit=12&skip=${nextPage}`,
        null,
        {
          filters: {}
        },
        navigate
      );
      // const response = await axios.get(`{{baseurl}}/api/v1/contacts?limit=2&skip=${nextPage * 2}`);
      const newContacts = response.data;

      if (newContacts.length < 2) {
        setHasMore(false);
      }

      const combinedContacts = [...contacts, ...newContacts];
      if (!isEqual(contacts, combinedContacts)) {
        setContacts(combinedContacts);
        setPage(nextPage);
      }
    } catch (error) {
      console.error('Failed to prefetch contacts', error);
    }
    setIsFetchingNextPage(false);
  };

  const getContactById = async (arg) => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}contacts/${arg}`, null, null, navigate);

      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const scheduleMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/replace_scheduled_message`, null, arg.payload, navigate);
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const sendScheduledMessageNow = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/send_scheduled_message_now`, null, null, navigate);

    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const takeActionMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/AI/take_action`, null, arg.payload, navigate);
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const generateAiMessages = async (arg) => {
    const data = await axiosRequest(
      'GET',
      `${config.apiUrl}contacts/${arg.contact_id}/AI/get_draft`,
      { agent_id: arg.agent_id },
      null,
      navigate
    );
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const cancelScheduleMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg}/cancel_queued_messages`, null, null, navigate);
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const toggleAutoPilot = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/${arg.id}/auto-pilot/state`, null, arg.payload);
    const index = contacts.findIndex((contact) => contact.id === arg.id);

    var tmp = [...contacts];

    tmp[index].active = arg.payload.state === true ? 1 : 0;

    setContacts(tmp);
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  const updateActiveAgent = async (arg) => {
    const data = await axiosRequest('put', `${config.apiUrl}contact/${arg.id}`, null, arg.payload);
    if (data.success === true) return data;
    else {
      setErrorResponse(data);
      setErrorModalOpen(true);
    }
  };

  // useEffect(() => {
  //   let filters = {};
  //   const data = {
  //     skip: page,
  //     limit: 10,
  //     payload: {
  //       filters
  //     }
  //   };

  //   getContacts(page);
  // }, [page]);

  return (
    <ContactsContext.Provider
      value={{
        setPage,
        page,
        isLoading,
        fetchingContacts,
        contacts,
        prefetchNextPage,
        setContacts,
        hasMore,
        getContactById,
        updateActiveAgent,
        toggleAutoPilot,
        cancelScheduleMesssage,
        generateAiMessages,
        scheduleMesssage,
        getContacts,
        takeActionMesssage,
        sendScheduledMessageNow
      }}
    >
      {children}
      {errorResponse ? <ErrorModal errorModalOpen={errorModalOpen} errorObject={errorResponse} onClose={hideErrorModal} /> : null}
    </ContactsContext.Provider>
  );
};

export { ContactsProvider, ContactsContext };
