import React from 'react';
import {createContext, useState, useRef, useEffect} from 'react';

export const AccountContext = createContext(null);

const AccountProvider = ({children}) => {
  const [account, setAccount] = useState({});
  const [lastUpdateTime, setLastUpdateTime] = useState(Math.floor(new Date().getTime() / 1000));
  const [selectedSetting, setSelectedSetting] = useState('account');
  const [selectedIndex, setSelectedIndex] = useState();
  const [toggleTest, setToggleTest] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [showRight, setShowRight] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [loadingGenerateModal, setLoadingGenerateModal] = useState(false);
  const [person, setPerson] = useState({});
  const [editSchedule, setEditSchedule] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [openModal, setModalOpen] = useState(false);
  const [cardContents, setCardContents] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const [enableLogs, setEnableLogs] = useState(false);
  const [errorObject, setErrorObject] = useState();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  return (
    <AccountContext.Provider
      value={{
        enableLogs,
        setEnableLogs,
        errorObject,
        setErrorObject,
        errorModalOpen,
        setErrorModalOpen,
        tabIndex,
        setTabIndex,
        disableInput,
        cardContents,
        showRight,
        setShowRight,
        toggleFilter,
        setToggleFilter,
        setCardContents,
        openModal,
        setModalOpen,
        handleClose,
        handleOpen,
        setDisableInput,
        account,
        setAccount,
        person,
        setPerson,
        selectedAgents,
        setSelectedAgents,
        editSchedule,
        setEditSchedule,
        toggleTest,
        setToggleTest,
        darkMode,
        setDarkMode,
        setLoadingGenerateModal,
        loadingGenerateModal,
        selectedSetting,
        setSelectedSetting,
        selectedIndex,
        setSelectedIndex,
        lastUpdateTime,
        setLastUpdateTime,
        isAuthenticated,
        setIsAuthenticated
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
