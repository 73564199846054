import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosRequest from 'utils/axiosRequest';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const BulkActionsContext = createContext();

const BulkActionsProvider = ({ children }) => {
  const navigate = useNavigate();

  const turnOffAllautoPilots = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/turn_off_all_auto_pilots`, null, null, navigate);
    return data;
  };

  const cancelAllScheduleMesssage = async (arg) => {
    const data = await axiosRequest('post', `${config.apiUrl}contacts/AI/cancel_all_scheduled_messages`, null, null, navigate);
    return data;
  };

  const contactsUpdateBulk = async (arg) => {
    const data = await axiosRequest('PUT', `${config.apiUrl}contacts/bulk/update_contact_json_bulk`, null, arg, navigate);
    return data;
  };

  return (
    <BulkActionsContext.Provider
      value={{
        cancelAllScheduleMesssage,
        contactsUpdateBulk,
        turnOffAllautoPilots
      }}
    >
      {children}
    </BulkActionsContext.Provider>
  );
};

export { BulkActionsProvider, BulkActionsContext };
