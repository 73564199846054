import React from 'react';
import { Paper, Typography } from '@mui/material';

import { useTheme } from '@emotion/react';


const CodeBlock = ({ code }) => {
  
  const theme  = useTheme();
  return (
    <Paper sx={{ padding: theme.spacing(2),
      backgroundColor: '#f0f0f0',
      fontFamily: 'monospace',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),}}>
      <Typography component="code" variant="body1">
        {code}
        
      </Typography>
    </Paper>
  );
};

export default CodeBlock;
