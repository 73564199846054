import React, {useState, useContext} from 'react';
import {Modal, Snackbar, Alert, Button, InputLabel, TextField, Stack} from '@mui/material';
import {AdminUserContext} from 'store/api/adminApi';
import {CircularProgress} from '@mui/material';
const DeleteUserModal = ({open, setOpen, user}) => {
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const {deleteAdminUser} = useContext(AdminUserContext);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDisplayAlert(false);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {username: user, password: password};
    deleteAdminUser(data).then((res) => {
      if (res.message == 'Password invalid') {
        setAlertMessage('Password incorrect');
        setDisplayAlert(true);
        setLoading(false);
      } else {
        setAlertMessage('User Deleted');
        setDisplayAlert(true);
        setLoading(false);
      }
    });

    //setOpen(false);
  };

  const handleClose = () => {
    setPassword('');

    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          width: '40%',
          height: '60vh',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '20px',
          borderLeft: '3px solid #EE5D50',
          boxShadow: '0px 3px 6px rgba(0,0,0,0.16)'
        }}
      >
        <h2>Deleting User?</h2>

        <div>
          <div style={{margin: '30px', padding: '10px'}}>
            <InputLabel htmlFor="firstname-signup">Are you sure you want to delete User: {user}</InputLabel>
          </div>
          <InputLabel htmlFor="firstname-signup">Password*</InputLabel>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Stack direction="row" justifyContent="space-evenly">
            <Button
              onClick={() => handleClose()}
              style={{
                display: 'flex',
                float: 'inline-end',
                background: '#EDF2F7',
                color: '#212733'
              }}
              variant="contained"
            >
              No
            </Button>

            <Button
              onClick={() => handleSubmit()}
              style={{
                display: 'flex',
                float: 'inline-end',
                background: '#EE5D50'
              }}
              variant="contained"
            >
              {loading ? <CircularProgress size={24} /> : 'Yes'}
            </Button>
          </Stack>
        </div>
        <Snackbar
          open={displayAlert}
          autoHideDuration={1000} // Adjust the duration as needed
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          onClose={handleCloseAlert}
        >
          <Alert severity="warning" sx={{width: '100%'}}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
