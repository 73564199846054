import axiosRequest from 'utils/axiosRequest';
import config from '../../config';

const getSupportTickets = async ({ navigate }) => {
  try {
    const response = await axiosRequest('GET', `${config.apiUrl}admin/support_tickets`, null, null, navigate);

    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const appendSupportTickets = async ({ navigate, payload }) => {
  try {
    const response = await axiosRequest('POST', `${config.apiUrl}user/append_support_ticket`, null, payload, navigate);

    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const getDebugLogs = async ({ navigate }) => {
  try {
    const response = await axiosRequest('GET', `${config.apiUrl}admin/debug/log`, null, null, navigate);

    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const getServerLogs = async ({ navigate }) => {
  try {
    const response = await axiosRequest('GET', `${config.apiUrl}admin/debug/server_log`, null, null, navigate);
    if (response.success == true) return response;
    else {
      // setErrorResponse(response);
      // setErrorModalOpen(true);
    }
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const getRuntimeErrors = async ({ navigate }) => {
  try {
    const response = await axiosRequest('GET', `${config.apiUrl}admin/debug/runtime_errors`, null, null, navigate);

    return response;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

export { getSupportTickets, appendSupportTickets, getDebugLogs, getServerLogs, getRuntimeErrors };
