import React, {useState} from 'react';
import fileUrl from 'assets/ivydoc.pdf';
import { Worker, Viewer , ScrollMode} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { pdfjs } from 'react-pdf';

const ModalExample = () => {

  return (
    <div style={{width: '100%', height: '90vh'}}>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer fileUrl={fileUrl} />
            </Worker>
    </div>
  );
};

export default ModalExample;
