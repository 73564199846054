import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'assets/css/index.css';
import 'simplebar/src/simplebar.css';
import './assets/third-party/apex-chart.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import './index.css';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </StrictMode>
);

reportWebVitals();
