import React, { createContext, useState } from 'react';
import axios from 'axios';
import axiosRequest from 'utils/axiosRequest';
import { useNavigate } from 'react-router-dom';
import ErrorModal from 'components/globalerror';
import config from '../../config';
const AdminUserContext = createContext();

const AdminUserProvider = ({ children }) => {
  const [errorResponse, setErrorResponse] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const hideErrorModal = () => {
    setErrorModalOpen(false);
  };

  const navigate = useNavigate();
  const [adminUsers, setAdminUsers] = useState([]);

  const getAdminUsers = async () => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}admin/users`, null, null, navigate);
      if (response.success === true) setAdminUsers(response.data);
      else {
        setErrorResponse(response);
        setErrorModalOpen(true);
      }
    } catch (error) {
      setErrorResponse(error.data);
      setErrorModalOpen(true);
    }
  };

  const getSingleAdminUsers = async (arg) => {
    try {
      const response = await axiosRequest('GET', `${config.apiUrl}admin/users/${arg}`, null, null, navigate);
      if (response.success === true) return response.data;
      else {
        setErrorResponse(response);
        setErrorModalOpen(true);
      }
    } catch (error) {
      setErrorResponse(error);
      setErrorModalOpen(true);
      console.error('Error fetching users:', error);
    }
  };

  const updateAdminUser = async (userId, updatedUserData) => {
    try {
      const response = await axios.put(`/api/v1/admin/users/:username`, updatedUserData);

      getAdminUsers();
      if (response.success === true) return response;
      else {
        setErrorResponse(response);
        setErrorModalOpen(true);
      }
    } catch (error) {
      setErrorResponse(error);
      setErrorModalOpen(true);
      console.error('Error updating user:', error);
    }
  };

  const deleteAdminUser = async (data) => {
    const params = { password: data.password };
    let user = data.username;
    try {
      const data = await axiosRequest('DELETE', `${config.apiUrl}admin/users/${user}`, null, params, navigate);

      getAdminUsers();
      if (data.success === true) return data;
      else {
        setErrorResponse(data);
        setErrorModalOpen(true);
      }
    } catch (error) {
      setErrorResponse(error);
      setErrorModalOpen(true);
      console.error('Error deleting user:', error);
    }
  };
  const createAdminUser = async (userData) => {
    try {
      const response = await axiosRequest('POST', `${config.apiUrl}admin/users`, null, userData, navigate);

      if (response.success === true) getAdminUsers();
      else {
        setErrorResponse(response);
        setErrorModalOpen(true);
      }
    } catch (error) {
      setErrorResponse(error);
      setErrorModalOpen(true);
      console.error('Error creating user:', error);
    }
  };

  return (
    <AdminUserContext.Provider
      value={{ adminUsers, updateAdminUser, createAdminUser, getAdminUsers, getSingleAdminUsers, deleteAdminUser }}
    >
      {children}
      {errorResponse ? <ErrorModal errorModalOpen={errorModalOpen} errorObject={errorResponse} onClose={hideErrorModal} /> : null}
    </AdminUserContext.Provider>
  );
};

export { AdminUserProvider, AdminUserContext };
