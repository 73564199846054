import React from 'react';

import TextWithLinks from 'utils/textFormatter';

const ResponseComponent = ({message}) => {
  return (
    <div
      style={{
        fontSize: '14px',
        margin: '0px',
        padding: '5px',
        fontFamily:
          'Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji", "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", "EmojiSymbols", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Noto Sans", sans-serif',

        whiteSpace: 'pre-wrap'
      }}
    >
      {/* Use backticks for multi-line string in JSX */}

      <TextWithLinks text={message} />
    </div>
  );
};

export default ResponseComponent;
