import React from 'react';
import { Container, Card, Typography } from '@mui/material';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from 'components/payment/PaymentForm';
import VuiBox from 'components/atoms/VuiBox';

// const stripePromise = loadStripe('YOUR_STRIPE_PUBLISHABLE_KEY');

const CheckoutPage = () => {
  return (
    <VuiBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Container sx={{ py: 4, width: '500px' }}>
        <Card sx={{ background: '#e1e1e1' }}>
          <Typography variant="h4" gutterBottom>
            Pay With Stripe
          </Typography>
          {/* <Elements stripe={stripePromise}> */}
          {/* <PaymentForm /> */}
          {/* </Elements> */}
        </Card>
      </Container>
    </VuiBox>
  );
};

export default CheckoutPage;
