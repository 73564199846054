import React from 'react';
import { useState, useEffect } from 'react';
import Breadcrumbs from 'examples/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import VuiBox from 'components/atoms/VuiBox';
import VuiTypography from 'components/atoms/VuiTypography';
import { navbar, navbarContainer, navbarRow } from 'examples/Navbars/DashboardNavbar/styles';

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const route = useLocation().pathname.split('/').slice(1);
  const [transparentNavbar, setTransparentNavbar] = useState(false);
  useEffect(() => {
    setNavbarType('sticky');
    function handleTransparentNavbar() {
      setTransparentNavbar(false);
    }

    window.addEventListener('scroll', handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, []);

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox pr={1}>
              <VuiTypography variant="button" fontWeight="large" color={light ? 'white' : 'dark'}>
                IVY Admin Panel
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.propTypes = {
  absolute: false,
  light: false,
  isMini: false
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool
};

export default DashboardNavbar;
