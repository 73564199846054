// AlertContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: '', severity: 'info', open: false });

  const showAlert = useCallback((message, severity = 'info') => {
    setAlert({ message, severity, open: true });
    setTimeout(() => {
      setAlert((prev) => ({ ...prev, open: false }));
    }, 3000);
  }, []);

  const closeAlert = () => setAlert((prev) => ({ ...prev, open: false }));

  return <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>{children}</AlertContext.Provider>;
};

export const useAlert = () => {
  return useContext(AlertContext);
};
