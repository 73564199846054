import React from 'react';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import PropTypes from 'prop-types';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import VuiBox from 'components/atoms/VuiBox';
import VuiTypography from 'components/atoms/VuiTypography';

const Breadcrumbs = ({ icon, title, route, light }) => {
  const routes = route.slice(0, -1);

  return (
    <VuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600])
          }
        }}
      >
        <Link to="/">
          <VuiTypography
            color={light ? 'white' : 'dark'}
            component="span"
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
            variant="body2"
          >
            <HomeOutlinedIcon fontSize="small" />
          </VuiTypography>
        </Link>
        {routes.map((el) => (
          <Link key={el} to={`/${el}`}>
            <VuiTypography
              color={light ? 'white' : 'dark'}
              component="span"
              fontWeight="regular"
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
              textTransform="capitalize"
              variant="button"
            >
              {el}
            </VuiTypography>
          </Link>
        ))}
        <VuiTypography
          color={light ? 'white' : 'dark'}
          fontWeight="regular"
          sx={{ lineHeight: 0 }}
          textTransform="capitalize"
          variant="button"
        >
          {title.replace('-', ' ')}
        </VuiTypography>
      </MuiBreadcrumbs>
      <VuiTypography color={light ? 'white' : 'dark'} fontWeight="bold" noWrap textTransform="capitalize" variant="h6">
        {title.replace('-', ' ')}
      </VuiTypography>
    </VuiBox>
  );
};

// Setting default values for the props of Breadcrumbs
Breadcrumbs.propTypes = {
  light: false
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool
};

export default Breadcrumbs;
