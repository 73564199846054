import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import {Box} from '@mui/material';
import AgentsInfo from 'pages/adminPanel/users/userDetails';
import SettingsHeader from 'components/templates/chat/SettingsHeader';

const AnchorDemoDrawer = ({handleClose, user, open}) => {
  return (
    <div id="mytoggle">
      <Drawer anchor="right" id="newtoggle" onClose={handleClose} open={open}>
        <Box sx={{width: 500}}>
          <SettingsHeader text="User Details" />
          <AgentsInfo agents={user} />
        </Box>
      </Drawer>
    </div>
  );
};
export default AnchorDemoDrawer;
