import ApiService from './apiService';
import { getBaseUrl } from './common-utils';

const axiosRequest = async (method, url, params = null, data = null) => {
  const config = {
    method: method.toLowerCase(),
    url: url,
    params: params,
    data: data
  };
  try {
    let response = await ApiService(config);

    return response.data;
  } catch (error) {
    console.log(error);
    if (error?.data?.message === 'User not authenticated') window.location.href = `${getBaseUrl()}/login`;

    return error;
  }
};

export default axiosRequest;
