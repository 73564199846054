import React, {useContext, useState} from 'react';
import {Modal, Button, InputLabel, TextField} from '@mui/material';
import {AdminUserContext} from 'store/api/adminApi';
import {CircularProgress} from '@mui/material';

const AddUserModal = ({open, setOpen}) => {
  const {createAdminUser} = useContext(AdminUserContext);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const handleCreate = ({username, password}) => {
    setLoading(true);
    createAdminUser({username: username, password: password}).then((res) => {
      setLoading(false);
      setOpen(false);
    });
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (password === confirmPassword) {
      handleCreate({username, password});

      // setOpen(false);
    } else {
      setPasswordError('Passwords do not match');
    }
  };

  const handleClose = () => {
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setPasswordError('');
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          width: '40%',
          height: '70vh',
          left: '50%',
          borderLeft: '4px solid #7F77F1',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '20px',
          boxShadow: '0px 3px 6px rgba(0,0,0,0.16)'
        }}
      >
        <h2>Add New User</h2>

        <div>
          <div
            style={{
              border: '1px solid black',
              margin: '30px',
              padding: '10px'
            }}
          >
            <InputLabel htmlFor="firstname-signup">User Name*</InputLabel>
            <TextField label="Username" value={username} onChange={handleUsernameChange} fullWidth margin="normal" variant="outlined" />
            <InputLabel htmlFor="firstname-signup">Password*</InputLabel>
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <InputLabel htmlFor="firstname-signup">Confirm Name*</InputLabel>
            <TextField
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={passwordError !== ''}
              helperText={passwordError}
            />
          </div>

          <Button
            onClick={() => handleSubmit()}
            style={{
              display: 'flex',
              float: 'inline-end',
              background: '#7F77F1',
              marginTop: '30px'
            }}
            variant="contained"
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Add Users'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
