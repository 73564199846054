import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import axiosRequest from 'utils/axiosRequest';

const GhlCallbackPage = () => {
  const navigate = useNavigate();
  const handleCallbacks = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      try {
        const response = await axiosRequest(
          'POST',
          ` https://api.steamlined.solutions/api/v1/callback/ghl?code=${code}`,
          null,
          null,
          navigate
        );
        if (response.success == true) navigate('/');
      } catch (error) {
        console.error('Error signing in user:', error);
      }
    } else {
      // If no code provided in query parameters
    }
  };
  useEffect(() => {
    handleCallbacks();
  }, []);

  return <div>processing ....</div>;
};

export default GhlCallbackPage;
