import React from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css'; // Choose your theme
import 'prismjs/components/prism-json'; // Import JSON language support

const CodeSnippet = ({ jsonObject }) => {
  const jsonString = JSON.stringify(jsonObject, null, 2);

  React.useEffect(() => {
    Prism.highlightAll();
  }, [jsonString]);

  return (
    <pre style={{ borderRadius: '10px', maxWidth: '70vw', overflow: 'auto' }}>
      <code className="language-json">{jsonString}</code>
    </pre>
  );
};

export default CodeSnippet;
