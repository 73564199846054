import React from 'react';

import { useEffect, useState } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Vision UI Dashboard React components
import VuiBox from 'components/atoms/VuiBox';

// Vision UI Dashboard React context

function DashboardLayout({ children }) {
  const [miniSidenav] = useState(true);
  const { pathname } = useLocation();
  const [layout, setLayout] = useState('dashboard');
  useEffect(() => {
    setLayout('dashboard');
  }, [pathname]);

  return (
    <VuiBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard
          })
        }
      })}
    >
      {children}
    </VuiBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardLayout;
