import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import VuiBox from 'components/atoms/VuiBox';
import { NavLink, useLocation } from 'react-router-dom';
const CustomBottomNavigation = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);
  const [activeRoute, setActiveRoute] = useState('');
  const contactRegex = /contacts/;
  const settingRegex = /settings/;
  const docsRegex = /docs/;

  const agentRegex = /agents/;

  const iconBackColorOpen = 'black';
  const iconBackColor = 'none';
  useEffect(() => {
    console.log(location.pathname);
  }, [location.pathname]);
  return (
    <Box sx={{ width: '100%', display: { xs: 'block' } }}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <VuiBox component={NavLink} to="/contacts">
          <BottomNavigationAction
            label="Contacts"
            icon={<ReviewsIcon />}
            style={{
              color: location.pathname === '/contacts' ? '#2083d4' : ''
            }}
          />
        </VuiBox>
        <VuiBox component={NavLink} to="/agents">
          <BottomNavigationAction
            label="Agents"
            icon={<SupportAgentIcon />}
            style={{
              color: location.pathname === '/agents' ? '#2083d4' : ''
            }}
          />
        </VuiBox>
        <VuiBox component={NavLink} to="/settings">
          <BottomNavigationAction
            label="Settings"
            icon={<SettingsSuggestIcon />}
            style={{
              color: location.pathname === '/settings' ? '#2083d4' : ''
            }}
          />
        </VuiBox>
        <VuiBox component={NavLink} to="/docs">
          <BottomNavigationAction
            label="Docs"
            icon={<DescriptionIcon />}
            style={{
              color: location.pathname === '/docs' ? '#2083d4' : ''
            }}
          />
        </VuiBox>
      </BottomNavigation>
    </Box>
  );
};

export default CustomBottomNavigation;
