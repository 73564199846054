import React from 'react';
import { Stack, Grid, Box, Button, Typography } from '@mui/material';
const EmergencySetting = () => {
  return (
    <Box sx={{ p: { xs: 0, lg: 5 } }}>
      <Stack direction="row" gap={3} justifyContent="center">
        <Typography sx={{ margin: 6, fontSize: '16px' }} variant="h3">
          Emergency Settings
        </Typography>
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{
              margin: { xs: 0, lg: 5 },

              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button sx={{ width: '50%', background: '#e57373' }} variant="contained">
              Cancel All Scheduled Messages
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              margin: { xs: 0, lg: 5 },

              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button sx={{ width: '50%', background: '#e57373' }} variant="contained">
              Turn Autopilots Off
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default EmergencySetting;
