import React from 'react';
import { useMemo } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// uuid is a library for generating unique id
import { v4 as uuidv4 } from 'uuid';

// @mui material components
import { Table as MuiTable } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// Vision UI Dashboard React components
import VuiBox from 'components/atoms/VuiBox';
import VuiAvatar from 'components/atoms/VuiAvatar';
import VuiTypography from 'components/atoms/VuiTypography';

// Vision UI Dashboard React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';

const Table = ({ columns, rows }) => {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(({ name, label, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
        color="text"
        component="th"
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        key={name}
        opacity={0.7}
        pb={1.25}
        pl={align === 'left' ? pl : 3}
        pr={align === 'right' ? pr : 3}
        pt={1.5}
        textAlign={align}
        width={width || 'auto'}
      >
        {label?.toUpperCase()}
      </VuiBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <VuiBox borderBottom={row.hasBorder ? `${borderWidth[1]} solid ` : null} component="td" key={uuidv4()} p={1}>
            <VuiBox alignItems="center" display="flex" px={1} py={0.5}>
              <VuiBox mr={2}>
                <VuiAvatar name={row[name][1]} size="sm" src={row[name][0]} variant="rounded" />
              </VuiBox>
              <VuiTypography color="white" fontWeight="medium" sx={{ width: 'max-content' }} variant="button">
                {row[name][1]}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        );
      } else {
        template = (
          <VuiBox
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${grey[700]}` : null}
            component="td"
            key={uuidv4()}
            p={1}
            textAlign={align}
          >
            <VuiTypography color="text" fontWeight="regular" sx={{ display: 'inline-block', width: 'max-content' }} variant="button">
              {row[name]}
            </VuiTypography>
          </VuiBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
};

// Setting default values for the props of Table
Table.propTypes = {
  columns: [],
  rows: [{}]
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object)
};

export default Table;
