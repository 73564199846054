import { Box, Typography, styled, Divider } from '@mui/material';
import React from 'react';
import Logo from 'assets/images/logo.png';
const EmptyChat = () => {
  return (
    <Component>
      <Image alt="img" src={Logo} />
      <Title>SteamLined Solution</Title>
      <SubTitle />
      <SubTitle>We automate your sales process</SubTitle>
      <StyledDivider />
    </Component>
  );
};

const Component = styled(Box)`
  background: #f8f9fa;
  padding: 30px 0;
  text-align: center;
  height: 90vh;
`;

const Image = styled('img')({
  width: 400,
  margin: 'auto',
  marginTop: 100
});

const Title = styled(Typography)`
  font-size: 32px;
  margin: 25px 0 10px 0;
  font-family: inherit;
  font-weight: 300;
  color: #415253;
`;
const SubTitle = styled(Typography)`
  font-size: 14px;
  color: #667781;
  font-weight: 400;
  font-family: inherit;
`;
const StyledDivider = styled(Divider)`
  margin: 40px 0;
  opacity: 0.4;
`;

export default EmptyChat;
