import React from 'react';
import {useTheme} from '@mui/material/styles';
import { Paper, Chip, Box } from '@mui/material';



const AllSourcesComponent = ({ sources }) => {
  const theme = useTheme();
  return (
    <Paper sx={{width: '100%',
      padding: theme.spacing(2),}}>
    
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'flex-start' }}>
        {sources.map((source, index) => (
          
            <Chip key={index} label={source} s sx={{fontSize: '10px', height: '15px'}} />
        
        ))}
     </Box>
    </Paper>
  );
};

export default AllSourcesComponent;
