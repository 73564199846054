// UserList.js
import React, {useContext, useEffect, useState} from 'react';
import {AdminUserContext} from 'store/api/adminApi';
import {useNavigate} from 'react-router-dom';
import AddUserModal from './createUser';

import colors from 'assets/theme/base/colors';
import Table from 'examples/Tables/Table';
import DeleteUserModal from './deleteUser';
import AnchorDemoDrawer from 'pages/landing/TestModal';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index';
import {Card} from '@mui/material';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index';
import VuiBox from 'components/atoms/VuiBox/index';
import VuiTypography from 'components/atoms/VuiTypography/index';
import VuiButton from 'components/atoms/VuiButton/index';
import linearGradient from 'themes/functions/linearGradient';
import VuiAvatar from 'components/atoms/VuiAvatar/index';
import VuiBadge from 'components/atoms/VuiBadge/index';
import avatar4 from 'assets/images/avatar4.png';
import EmptyChat from 'components/agents/agents/EmptyChat';
const Function = ({job, org}) => {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography color="white" fontWeight="medium" variant="caption">
        {job}
      </VuiTypography>
      <VuiTypography color="text" variant="caption">
        {org}
      </VuiTypography>
    </VuiBox>
  );
};

const Author = ({image, name, email}) => {
  return (
    <VuiBox alignItems="center" display="flex" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar alt={name} size="sm" src={image} variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography color="white" fontWeight="medium" variant="button">
          {name}
        </VuiTypography>
        <VuiTypography color="text" variant="caption">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
};

const DashBoard = () => {
  const [rows, setRows] = useState([]);
  const columns = [
    {label: 'UserName', name: 'user_name', align: 'left'},
    {label: 'Billing', name: 'Billing', align: 'left'},
    {label: 'Plan', name: 'Plan', align: 'left'},
    {label: 'action', name: 'action', align: 'left'}
  ];
  const {gradients} = colors;
  const {cardContent} = gradients;
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedDetail, setSelectedDetail] = useState();
  const {adminUsers, getAdminUsers, getSingleAdminUsers} = useContext(AdminUserContext);
  const [openModal, setModalOpen] = useState(false);
  const handleOpenEdit = () => setModalOpen(true);
  const handleCloseEdit = () => setModalOpen(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleUserDetails = (user) => {
    getSingleAdminUsers(user.user_name).then((res) => {
      //  setRows(adminUsers);
      setSelectedDetail(res);
      handleOpenEdit();
    });
  };
  const convertedRows = () => {
    const newobj = adminUsers.map((item) => ({
      Billing: item.Billing.amount,
      Plan: item.Plan.description,
      user_name: item.user_name
    }));

    const convertedRows = newobj.map((item) => ({
      user_name: <Author email={item.user_name} image={avatar4} name={item.user_name} />,
      Billing: <Function job={item.Billing} org="" />,
      Plan: (
        <VuiBadge
          badgeContent={item.Plan}
          color="success"
          container
          size="xs"
          sx={({palette: {white, success}, borders: {borderRadius, borderWidth}}) => ({
            background: success.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main
          })}
          variant="standard"
        />
      ),
      action: (
        <VuiBox mr={1}>
          <VuiButton
            color="text"
            onClick={() => {
              handleUserDetails(item);
              setEditOpen(true);
            }}
            variant="text"
          >
            EDIT
          </VuiButton>

          <VuiButton
            color="error"
            onClick={() => {
              setSelectedUser(item.user_name);
              setDeleteOpen(true);
            }}
            variant="text"
          >
            DELETE
          </VuiButton>
        </VuiBox>
      )
    }));

    return convertedRows;
  };
  useEffect(() => {
    setLoading(true);
    getAdminUsers().then(() => {
      setRows(convertedRows());
      setLoading(false);
    });
  }, []);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <DashboardLayout>
   
      <EmptyChat/>
    </DashboardLayout>
  );
};

export default DashBoard;
