// AppContext.js
import React, {createContext, useState, useContext} from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({children}) => {
  const [globalError, setGlobalError] = useState(null);

  const showGlobalError = (errorMessage) => {
    setGlobalError(errorMessage);
  };

  const clearGlobalError = () => {
    setGlobalError(null);
  };

  return (
    <AppContext.Provider
      value={{
        globalError,
        showGlobalError,
        clearGlobalError
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
