import React from 'react';

import { forwardRef } from 'react';

import PropTypes from 'prop-types';

import VuiAvatarRoot from 'components/atoms/VuiAvatar/VuiAvatarRoot';

const VuiAvatar = forwardRef(({ background, size, shadow, ...rest }, ref) => (
  <VuiAvatarRoot ref={ref} ownerState={{ shadow, background, size }} {...rest} />
));

// Setting default values for the props of VuiAvatar
VuiAvatar.defaultProps = {
  background: 'transparent',
  size: 'md',
  shadow: 'none'
};

// Typechecking props for the VuiAvatar
VuiAvatar.propTypes = {
  background: PropTypes.oneOf(['transparent', 'primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  shadow: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset'])
};

export default VuiAvatar;
