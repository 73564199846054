
import React, {useContext, useEffect, useState} from 'react';
import {AdminUserContext} from 'store/api/adminApi';
import TableRow from '@mui/material/TableRow';
import AddUserModal from './createUser';
import {v4 as uuidv4} from 'uuid';
import {Table as MuiTable} from '@mui/material';
import colors from 'assets/theme/base/colors';
import TableBody from '@mui/material/TableBody';
import typography from 'assets/theme/base/typography';
import Table from 'examples/Tables/Table';
import DeleteUserModal from './deleteUser';
import AnchorDemoDrawer from 'pages/landing/TestModal';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index';
import {Card, TableContainer} from '../../../../node_modules/@mui/material/index';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index';
import VuiBox from 'components/atoms/VuiBox/index';
import VuiTypography from 'components/atoms/VuiTypography/index';
import VuiButton from 'components/atoms/VuiButton/index';
import linearGradient from 'themes/functions/linearGradient';
import VuiAvatar from 'components/atoms/VuiAvatar/index';
import VuiBadge from 'components/atoms/VuiBadge/index';
import avatar4 from 'assets/images/avatar4.png';
import CustomLoading from 'components/customLoading/CustomLoading';
import borders from 'assets/theme/base/borders';
const Function = ({job, org}) => {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography color="white" fontWeight="medium" variant="caption">
        {job}
      </VuiTypography>
      <VuiTypography color="text" variant="caption">
        {org}
      </VuiTypography>
    </VuiBox>
  );
};

const Author = ({image, name, email}) => {
  return (
    <VuiBox alignItems="center" display="flex" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar alt={name} size="sm" src={image} variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography color="white" fontWeight="medium" variant="button">
          {name}
        </VuiTypography>
        <VuiTypography color="text" variant="caption">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
};

const UserList = () => {
  const [rows, setRows] = useState([]);
  const {borderWidth} = borders;
  const {grey} = colors;
  const {size, fontWeightBold} = typography;
  const columns = [
    {label: 'UserName', name: 'user_name', align: 'left'},
    {label: 'Billing', name: 'Billing', align: 'left'},
    {label: 'Plan', name: 'Plan', align: 'left'},
    {label: 'action', name: 'action', align: 'left'}
  ];
  const {gradients} = colors;
 
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedDetail, setSelectedDetail] = useState();
  const {adminUsers, getAdminUsers, getSingleAdminUsers} = useContext(AdminUserContext);
  const [openModal, setModalOpen] = useState(false);
  const handleOpenEdit = () => setModalOpen(true);
  const handleCloseEdit = () => setModalOpen(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleUserDetails = (user) => {
    getSingleAdminUsers(user.user_name).then((res) => {
      //  setRows(adminUsers);
      setSelectedDetail(res);
      handleOpenEdit();
    });
  };
  const convertedRows = () => {
    const newobj = adminUsers.map((item) => ({
      Billing: item.Billing.amount,
      Plan: item.Plan.description,
      user_name: item.user_name
    }));

    const converRows = newobj.map((item) => ({
      user_name: <Author email={item.user_name} image={avatar4} name={item.user_name} />,
      Billing: <Function job={item.Billing} org="" />,
      Plan: (
        <VuiBadge
          badgeContent={item.Plan}
          color="success"
          container
          size="xs"
          sx={({palette: {white, success}, borders: {borderRadius, borderWidth}}) => ({
            background: success.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main
          })}
          variant="standard"
        />
      ),
      action: (
        <VuiBox mr={1}>
          <VuiButton
            color="text"
            onClick={() => {
              handleUserDetails(item);
              setEditOpen(true);
            }}
            variant="text"
          >
            EDIT
          </VuiButton>

          <VuiButton
            color="error"
            onClick={() => {
              setSelectedUser(item.user_name);
              setDeleteOpen(true);
            }}
            variant="text"
          >
            DELETE
          </VuiButton>
        </VuiBox>
      )
    }));

    return converRows;
  };
  useEffect(() => {
    setLoading(true);
    getAdminUsers().then(() => {
     
      setLoading(false);
    });
  }, []);
  useEffect(()=>{
    setRows(convertedRows());
  },[adminUsers])

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading? <>
        <Card sx={{height: '40vh'}}>
                  <CustomLoading message="Loading Users ..." />
                </Card>
      </>:
      <VuiBox background="blue" py={3}>
        <VuiBox mb={3}>
          <Card
            sx={{
              borderRadius: '30px',
              p: 5,
              background: linearGradient('gray', gradients.sidenav.state, gradients.sidenav.deg)
            }}
          >
            <VuiBox alignItems="center" display="flex" justifyContent="space-between" mb="22px">
              <VuiTypography color="white" variant="lg">
                User Management
              </VuiTypography>

              <VuiButton color="success" component="a" onClick={() => setOpen(true)} rel="noreferrer" target="_blank" variant="gradient">
                Add User
              </VuiButton>
            </VuiBox>
            <VuiBox
              sx={{
                '& th': {
                  borderBottom: ({borders: {borderWidth}, palette: {grey}}) => `${borderWidth[1]} solid ${grey[700]}`
                },
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({borders: {borderWidth}, palette: {grey}}) => `${borderWidth[1]} solid ${grey[700]}`
                  }
                }
              }}
            >
               <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>
              {
                columns.map(({name, label, align, width}, key) => {
                  let pl;
                  let pr;
              
                  if (key === 0) {
                    pl = 3;
                    pr = 3;
                  } else if (key === columns.length - 1) {
                    pl = 3;
                    pr = 3;
                  } else {
                    pl = 1;
                    pr = 1;
                  }
              
                  return (
                    <VuiBox
                      borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
                      color="text"
                      component="th"
                      fontSize={size.xxs}
                      fontWeight={fontWeightBold}
                      key={name}
                      opacity={0.7}
                      pb={1.25}
                      pl={align === 'left' ? pl : 3}
                      pr={align === 'right' ? pr : 3}
                      pt={1.5}
                      textAlign={align}
                      width={width || 'auto'}
                    >
                      {label?.toUpperCase()}
                    </VuiBox>
                  );
                })
              }
            </TableRow>
          </VuiBox>
          <TableBody>{rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({name, align}) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <VuiBox borderBottom={row.hasBorder ? `${borderWidth[1]} solid ` : null} component="td" key={uuidv4()} p={1}>
            <VuiBox alignItems="center" display="flex" px={1} py={0.5}>
              <VuiBox mr={2}>
                <VuiAvatar name={row[name][1]} size="sm" src={row[name][0]} variant="rounded" />
              </VuiBox>
              <VuiTypography color="white" fontWeight="medium" sx={{width: 'max-content'}} variant="button">
                {row[name][1]}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        );
      } else {
        template = (
          <VuiBox
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${grey[700]}` : null}
            component="td"
            key={uuidv4()}
            p={1}
            textAlign={align}
          >
            <VuiTypography color="text" fontWeight="regular" sx={{display: 'inline-block', width: 'max-content'}} variant="button">
              {row[name]}
            </VuiTypography>
          </VuiBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  })}</TableBody>
        </MuiTable>
      </TableContainer>
             
            </VuiBox>
          </Card>
        </VuiBox>
      </VuiBox>
      }
      <AddUserModal open={open} setOpen={setOpen} />
      <DeleteUserModal open={deleteOpen} setOpen={setDeleteOpen} user={selectedUser} />
      <AnchorDemoDrawer handleClose={handleCloseEdit} open={openModal} setOpen={setModalOpen} user={selectedDetail} />

         </DashboardLayout>
  );
};

export default UserList;
