import React from 'react';
import {Typography} from '@mui/material';

const SuccessPage = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white'}}>
      <div
        style={{
          width: 500,
          height: 250,
          borderRadius: '20px',
          backgroundColor: 'black',
          display: 'flex',
          justifyContent: 'center',
          boxShadow: '0 4px 108px rgba(0, 0, 0, 0.5)',
          alignItems: 'center'
        }}
      >
        <Typography sx={{color: 'white', textAlign: 'center'}} variant="h4">
          Ivy will message you soon
        </Typography>
      </div>
    </div>
  );
};

export default SuccessPage;
