// UserList.js
import React, {useEffect, useState} from 'react';

import {getServerLogs} from 'store/api/supportTicket';
import CodeBlock from 'components/CodeBlocks';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index';
import VuiBox from 'components/atoms/VuiBox/index';
import {Card} from '../../../../node_modules/@mui/material/index';
import linearGradient from 'themes/functions/linearGradient';
import colors from 'assets/theme/base/colors';
import VuiTypography from 'components/atoms/VuiTypography/index';
import ErrorModal from 'components/globalerror';
import CustomLoading from 'components/customLoading/CustomLoading';
const ServerLogs = () => {
  const [errorResponse, setErrorResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const hideErrorModal = () => {
    setErrorModalOpen(false);
  };
  const {gradients} = colors;
  const navigate = useState();
  const [serverLogs, setServerLogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    getServerLogs(navigate).then((res) => {
      if (res?.success === true) {
        setServerLogs(res.data);
        setLoading(false);
      }else {
        setErrorResponse(res);
        setErrorModalOpen(true);
      }
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox background="blue" py={3}>
        <VuiBox mb={3}>
          <Card
            sx={{
              borderRadius: '30px',
              p: 5,
              background: linearGradient('gray', gradients.sidenav.state, gradients.sidenav.deg)
            }}
          >
            <VuiBox alignItems="center" display="flex" justifyContent="space-between" mb="22px">
              <VuiTypography color="white" variant="lg">
                Server Logs
              </VuiTypography>
            </VuiBox>
            {loading? <>
        <Card sx={{height: '40vh'}}>
                  <CustomLoading message="Loading ..." />
                </Card>
      </>:
            <VuiBox
              sx={{
                '& th': {
                  borderBottom: ({borders: {borderWidth}, palette: {grey}}) => `${borderWidth[1]} solid ${grey[700]}`
                },
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({borders: {borderWidth}, palette: {grey}}) => `${borderWidth[1]} solid ${grey[700]}`
                  }
                }
              }}
            >
              {serverLogs.map((log, index) => (
                <CodeBlock code={log} key={index} sx={{display: 'flex', direction: 'column'}} />
              ))}
            </VuiBox>
}
          </Card>
        </VuiBox>
      </VuiBox>
      {errorResponse ? <ErrorModal errorModalOpen={errorModalOpen} errorObject={errorResponse} onClose={hideErrorModal} /> : null}
    </DashboardLayout>
  );
};

export default ServerLogs;
