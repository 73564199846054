// ApiService.js
import axios from 'axios';
import Cookies from 'js-cookie';

const ApiService = axios.create({
  withCredentials: true
});

ApiService.interceptors.request.use(
  (config) => {
    if (!config.url.includes('login') && Cookies.get('isAuthenticated')) {
      // Add authentication token to headers if user is authenticated
      const token = Cookies.get('isAuthenticated');
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ApiService.interceptors.response.use(
  (response) => {
    if (
      (response && response.data && response.data.message === 'User not authenticated') ||
      response.data.message === 'User not authorized'
    ) {
      return Promise.reject(response);
    }

    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.success === false &&
      error.response.message === 'User not authenticated'
    ) {
      // Handle the unauthenticated user scenario here
      console.error('User not authenticated');
    }
    return Promise.reject(error);
  }
);

export default ApiService;
