import React from 'react';
import { forwardRef } from 'react';
import VuiBoxRoot from 'components/atoms/VuiBox/VuiBoxRoot';
import PropTypes from 'prop-types';
const VuiBox = forwardRef(({ variant, background, color, opacity, borderRadius, shadow, ...rest }, ref) => (
  <VuiBoxRoot {...rest} ownerState={{ variant, background, color, opacity, borderRadius, shadow }} ref={ref} />
));

VuiBox.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string
};
export default VuiBox;
