// ==============================|| OVERRIDES - Accordion ||============================== //

export default {
  propTypes: {
    disableGutters: true
  },

  styleOverrides: {
    root: {
      '&:before': {
        backgroundColor: 'gray'
      }
    }
  }
};
