import React from 'react';
import {Stack, Grid, InputAdornment, Box, Button, Select, Typography, MenuItem, TextField} from '@mui/material';

import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import {useEffect, useState} from 'react';
import {appendSupportTickets} from 'store/api/supportTicket';
import {useNavigate} from '../../../../node_modules/react-router-dom/dist/index';
const SupportSetting = () => {
  const categories = ['Password reset request', 'Issue/Bug report', 'Other Support ticket', 'Feature request', 'General Feedback'];
  const [category, setCategory] = useState('');
  const [notes, setNotes] = useState('');
  const [enableAlert, setEnableAlert] = useState(false);
  const navigate = useNavigate();
  const handleSendSupport = () => {
    const payload = {
      Category: category,
      Notes: notes
    };
    appendSupportTickets({navigate, payload}).then(() => {
      setEnableAlert(true);
    });
  };

  useEffect(() => {
    if (enableAlert === true) setTimeout(() => setEnableAlert(false), 4000);
  }, [enableAlert]);

  return (
    <Box sx={{width: '100%', p: 5}}>
      {enableAlert ? (
        <Alert icon={<CheckIcon fontSize="inherit" />} onClose={() => setEnableAlert(false)} severity="success">
          your ticket is sent please wait patiently
        </Alert>
      ) : null}
      <Stack direction="row" gap={3} justifyContent="center">
        <Typography sx={{margin: 6, fontSize: '16px'}} variant="h3">
          Feedback and Support
        </Typography>
      </Stack>

      <Grid container spacing={1}>
        <Grid xs={4} />
        <Grid xs={6}>
          <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
            <Typography> Category</Typography>
          </Box>
        </Grid>
        <Grid xs={4} />

        <Grid item xs={6}>
          <Select onChange={(e) => setCategory(e.target.value)} placeholder="select Category" style={{width: '100%'}}>
            {categories.map((category) => {
              return (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={4} />
        <Grid xs={6}>
          <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
            <Typography>Content</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} />

        <Grid item xs={6}>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end" />
            }}
            fullWidth
            margin="normal"
            maxRows={12}
            minRows={5}
            multiline
            onChange={(e) => setNotes(e.target.value)}
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid xs={12}>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button onClick={() => handleSendSupport()} variant="contained">
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SupportSetting;
