import React, {useEffect} from 'react';
import {Typography, Box, Stack, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AllSourcesComponent from 'components/AlltagList';

const AgentsInfo = ({agents}) => {
  return (
    <Box sx={{paddingLeft: 5}}>
      <Stack direction="row">
        <Typography>User Name :</Typography>
        <Typography>{agents?.user_name}</Typography>
      </Stack>
      <Divider />
      <Stack direction="row">
        <Typography>Billing :</Typography>
        <Typography>{agents?.Billing}</Typography>
      </Stack>
      <Divider />
      <Stack direction="row">
        <Typography>Plan: </Typography>
        <Typography>{agents?.Plan?.price}</Typography>
      </Stack>
      <Divider />
      <Typography>Sources</Typography>
      <Divider />
      <AllSourcesComponent sources={agents?.all_sources} />
      <Typography>Agents</Typography>
      {agents?.Agents.map((agent) => (
        <Accordion key={agent.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{agent.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Typography variant="h6">General Information</Typography>
              <Typography>{agent.general_information.Agent_name}</Typography>
              <Typography>{agent.general_information.Company_name}</Typography>
              {/* Render other general information fields */}

              <Typography variant="h6">FAQs</Typography>
              <List>
                {agent.faqs.map((faq, index) => (
                  <ListItem key={index} sx={{border: '1px solid gray', borderRadius: '10px'}}>
                    <ListItemText primary={faq.question} secondary={faq.answer} />
                  </ListItem>
                ))}
              </List>

              <Typography variant="h6">Company Information</Typography>
              <Typography>{agent.Gmail_email}</Typography>
              {/* <List>
                {agent.follow_ups.map((followUp, index) => (
                  <ListItem sx={{border: '1px solid gray', margin: '5px 0px', wordBreak: 'break-word', borderRadius: '10px'}} key={index}>
                    <ListItemText primary={followUp.message} />
                  </ListItem>
                ))}
              </List> */}

              {/* Render other sections like message drip, resources, etc. */}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AgentsInfo;
