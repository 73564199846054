// UserList.js
import React, {useEffect, useState} from 'react';
import {IoLogoCss3} from 'react-icons/io5';
import {Divider} from '@mui/material';
import {getDebugLogs} from 'store/api/supportTicket';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar/index';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout/index';
import VuiBox from 'components/atoms/VuiBox/index';
import {Card} from '../../../../node_modules/@mui/material/index';
import VuiTypography from 'components/atoms/VuiTypography/index';
import TimelineItem from 'examples/Timeline/TimelineItem';
import linearGradient from 'themes/functions/linearGradient';
import colors from 'assets/theme/base/colors';
import CustomLoading from 'components/customLoading/CustomLoading';

const UserList = () => {
  const navigate = useState();
  const [debugLogs, setDebugLogs] = useState([]);
 
  const [loading, setLoading] = useState(true);

 

  useEffect(() => {
    setLoading(true);
    getDebugLogs(navigate).then((res) => {
      setDebugLogs(res.data.slice(0, 10));
      setLoading(false);
      // convertStringtojson(res.data[1]);
    });
  }, []);


  const {gradients} = colors;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading? <>
        <Card sx={{height: '40vh'}}>
                  <CustomLoading message="Loading ..." />
                </Card>
      </>:
      <VuiBox background="blue" py={3}>
        <VuiBox mb={3}>
          <Card
            sx={{
              borderRadius: '30px',
              p: 5,
              background: linearGradient('gray', gradients.sidenav.state, gradients.sidenav.deg)
            }}
          >
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Debug Logs
              </VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                '& th': {
                  borderBottom: ({borders: {borderWidth}, palette: {grey}}) => `${borderWidth[1]} solid ${grey[700]}`
                },
                '& .MuiTableRow-root:not(:last-child)': {
                  '& td': {
                    borderBottom: ({borders: {borderWidth}, palette: {grey}}) => `${borderWidth[1]} solid ${grey[700]}`
                  }
                }
              }}
            >
              {debugLogs.map((log, index) => (
                <>
                  <TimelineItem icon={<IoLogoCss3 size="16px" color="success" />} title={log} dateTime="" />
                  <Divider />
                </>
              ))}
            </VuiBox>
          </Card>
        </VuiBox>

      </VuiBox>
}
    </DashboardLayout>
  );
};

export default UserList;
