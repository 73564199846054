import React from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import axiosRequest from 'utils/axiosRequest';

import NoticeAlertDialog from 'components/NoticeAlertBox';
import config from '../config';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [errorResponse, setErrorResponse] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(null);
  };
  const navigate = useNavigate();

  const login = async (data) => {
    try {
      const response = await axiosRequest('POST', `${config.apiUrl}login`, null, data);

      if (response.success === true) {
        if (data.user_name === 'admin') navigate('/adminPanel/dashboard');
        else navigate('/contacts');
        setUser(data);
      } else {
        setErrorResponse(response);
        setDialogOpen(true);
      }
      return response;
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const signup = async (arg) => {
    try {
      const response = await axiosRequest('POST', `${config.apiUrl}signup`, null, arg);
      if (response.success === true) {
        setUser(response);
        navigate('/contacts');
      } else {
        setErrorResponse(response);
        setDialogOpen(true);
      }
      return response;
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const logout = () => {
    setUser(null);
    navigate('/login', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      signup,
      logout
    }),
    [user]
  );
  return (
    <AuthContext.Provider value={value}>
      {children}
      {errorResponse ? (
        <NoticeAlertDialog content={errorResponse.message} onClose={handleClose} onConfirm={handleClose} open={dialogOpen} title="Notice" />
      ) : null}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
